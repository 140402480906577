import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Cookies from 'js-cookie';
import MoonLoader from 'react-spinners/MoonLoader';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
/* --- import components ----*/
import {
  InputForm,
  InputPhone,
  Label,
  RightInputWrapper,
  TwoInputWrapper,
} from 'src/components/Form/elements';
import ErrorMessage from 'src/components/Form/ErrorMessage';
import { GRC } from 'src/components/Form';
import { InputFormLarge } from 'src/components/Form/elements';
import { NextBtn } from 'src/components/Form/elements';
import { SentenceSmall } from 'src/components/Typography';
import CheckboxComp from 'src/components/EmailForm/CheckboxComp';
/* --- import Style and Icons ----*/
import colors from 'src/style/colors';
import { flags } from '../../Icons/Flags';
import { mediaQueries } from 'src/style/breakpoints';

const FormBlockSchema = Yup.object().shape({
  firstName: Yup.string().required('Champ obligatoire'),
  lastName: Yup.string().required('Champ obligatoire'),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Email non valide')
    .required('Champ obligatoire'),
  zipCode: Yup.string().required('Champ obligatoire'),
  phoneNumber: Yup.string()
    .required('Champ obligatoire')
    .test({
      test: (num) => {
        if (num) {
          if (num.indexOf('+330') !== -1) {
            num = num.replace('+330', '+33');
            return isPossiblePhoneNumber(num);
          } else {
            return isPossiblePhoneNumber(num);
          }
        } else {
          return true;
        }
      },
      message: 'Numéro de téléphone non valide',
    }),
  optIn: Yup.boolean().oneOf([true], 'Veuillez cocher la case'),
});

const action = 'Programme';

const FirstBlock = ({ setValues, setFormSuccess }) => {
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [GRCtoken, setGRCToken] = React.useState(null);

  return (
    <GRC setGRCToken={setGRCToken} action={action}>
      <Formik
        validationSchema={FormBlockSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          zipCode: '',
          optIn: false,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!GRCtoken) {
            setError(true);
          }
          setIsLoading(true);
          setSubmitting(true);
          const { firstName, lastName, phoneNumber, email, zipCode, optIn } = values;

          setValues(values);

          try {
            const utmSource = Cookies.get('tracking') || Cookies.get('utm_source') || '';
            const gclid = Cookies.get('_gcl_aw') || '';
            const fbclid = Cookies.get('fbclid') || '';
            const referrer = Cookies.get('referrer') || '';

            const bodyParameters = {
              firstName,
              lastName,
              phoneNumber,
              email,
              zipCode,
              mailinglist: optIn,
              source: document.location.href,
              utmSource,
              gclid,
              fbclid,
              referrer,
              action,
              GRCtoken,
            };

            const response = await axios.post(
              `${process.env.GATSBY_BASE_URL_NEW_API}/forms/syllabus`,
              {
                ...bodyParameters,
                track: {
                  utmSource: Cookies.get('utm_source') || '',
                  utmMedium: Cookies.get('utm_medium') || '',
                  utmCampaign: Cookies.get('utm_campaign') || '',
                  utmCampaignId: Cookies.get('utm_campaign_id') || '',
                  adGroupName: Cookies.get('adgroup_name') || '',
                  utmAdGroupId: Cookies.get('utm_adgroup_id') || '',
                  utmTerm: Cookies.get('utm_term') || '',
                  matchType: Cookies.get('matchtype') || '',
                  utmContent: Cookies.get('utm_content') || '',
                },
              }
            );

            if (response.data.message === 'EMAIL SENT') {
              setFormSuccess(true);
              setSubmitting(false);
              window.dataLayer.push({
                event: 'receiveSyllabus',
                firstName,
                lastName,
                phoneNumber,
                email,
                zipCode,
              });
              if (window.lintrk) window.lintrk('track', { conversion_id: 9849700 });
            } else {
              setError(true);
            }
          } catch (error) {
            console.error(error.message);
            setError(true);
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          isValid,
          setFieldValue,
          isSubmitting,
          dirty,
        }) => (
          <Form>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                marginTop: !mediaQueries.phoneOnly ? 56 : 40,
              }}
            >
              <TwoInputWrapper>
                <div>
                  <div css={{ display: 'flex', flexDirection: 'row' }}>
                    <Label>Prénom&nbsp;</Label>
                    <SentenceSmall css={{ color: colors.purpleDark }}>*</SentenceSmall>
                  </div>
                  <InputForm
                    name="firstName"
                    errorStyle={errors.firstName && touched.firstName ? true : false}
                    onChange={handleChange}
                    value={values.firstName}
                    css={{
                      [mediaQueries.tabletLandscapeUp]: {
                        minWidth: 220,
                      },
                    }}
                  />
                  <ErrorMessage
                    isVisible={errors.firstName && touched.firstName}
                    message={errors.firstName}
                  />
                </div>
                <RightInputWrapper>
                  <div css={{ display: 'flex', flexDirection: 'row' }}>
                    <Label>Nom&nbsp;</Label>
                    <SentenceSmall css={{ color: colors.purpleDark }}>*</SentenceSmall>
                  </div>
                  <InputForm
                    name="lastName"
                    errorStyle={errors.lastName && touched.lastName ? true : false}
                    onChange={handleChange}
                    value={values.lastName}
                    css={{
                      [mediaQueries.tabletLandscapeUp]: {
                        minWidth: 220,
                      },
                    }}
                  />
                  <ErrorMessage
                    isVisible={errors.lastName && touched.lastName}
                    message={errors.lastName}
                  />
                </RightInputWrapper>
              </TwoInputWrapper>

              <TwoInputWrapper
                css={{
                  paddingTop: !mediaQueries.phoneOnly ? 40 : 35,
                }}
              >
                <div>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Label>Code postal&nbsp;</Label>
                    <SentenceSmall css={{ color: colors.purpleDark }}>*</SentenceSmall>
                  </div>
                  <InputForm
                    name="zipCode"
                    errorStyle={errors.zipCode && touched.zipCode ? true : false}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^0-9]/g, '');
                      setFieldValue('zipCode', newValue);
                    }}
                    value={values.zipCode}
                    css={{
                      [mediaQueries.tabletLandscapeUp]: {
                        minWidth: 220,
                      },
                    }}
                  />
                  <ErrorMessage
                    isVisible={errors.zipCode && touched.zipCode}
                    message={errors.zipCode}
                  />
                </div>

                <RightInputWrapper>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Label>Téléphone</Label>
                  </div>
                  <InputPhone
                    errors={errors}
                    touched={touched}
                    placeholder="Entrez votre numéro"
                    autoComplete="tel"
                    country="FR"
                    defaultCountry="FR"
                    flags={flags}
                    displayInitialValueAsLocalNumber={true}
                    value={values.phoneNumber}
                    onChange={(number) => {
                      setFieldValue('phoneNumber', number);
                    }}
                    css={{
                      [mediaQueries.tabletLandscapeUp]: {
                        minWidth: 220,
                      },
                    }}
                  />
                  <ErrorMessage
                    isVisible={errors.phoneNumber && touched.phoneNumber}
                    message={errors.phoneNumber}
                  />
                </RightInputWrapper>
              </TwoInputWrapper>

              <TwoInputWrapper
                css={{
                  paddingTop: !mediaQueries.phoneOnly ? 40 : 35,
                }}
              >
                <div css={{ width: '100%' }}>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Label>Email&nbsp;</Label>
                    <SentenceSmall css={{ color: colors.purpleDark }}>*</SentenceSmall>
                  </div>
                  <InputFormLarge
                    name="email"
                    type="text"
                    errorStyle={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    value={values.email}
                    css={{
                      [mediaQueries.tabletLandscapeUp]: {
                        minWidth: 220,
                      },
                    }}
                  />
                  <ErrorMessage isVisible={errors.email && touched.email} message={errors.email} />
                </div>
              </TwoInputWrapper>

              <div css={{ marginTop: 40, textAlign: 'left' }}>
                <CheckboxComp
                  text="J'accepte de recevoir par mail les communications du Reacteur (dont le programme
                    de formation). *"
                  value={values.optIn}
                  onChange={() => {
                    setFieldValue('optIn', values.optIn !== true ? true : false);
                  }}
                  errorDisabled={false}
                  errorIsVisible={errors.optIn && touched.optIn}
                  errorMessage={errors.optIn}
                />
              </div>

              <div css={{ paddingBottom: 10 }}>
                <NextBtn
                  type="submit"
                  isFullWidth
                  css={{
                    display: 'flex',
                    width: '100%',
                    marginTop: 50,
                    cursor: isSubmitting === true && 'not-allowed',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  disabled={isSubmitting === true}
                  disabledStyle={isValid === false || !dirty}
                >
                  {isLoading ? <MoonLoader color={'#fff'} size={20} /> : 'Recevoir notre programme'}
                </NextBtn>
                {error && (
                  <SentenceSmall css={{ color: colors.red, marginTop: '10px' }}>
                    Une erreur est survenue, veuillez recommencer
                  </SentenceSmall>
                )}
              </div>
              <SentenceSmall css={{ textAlign: 'left' }}>* Champ obligatoire</SentenceSmall>
            </div>
          </Form>
        )}
      </Formik>
    </GRC>
  );
};

export default FirstBlock;
